<script>
import isNil from 'lodash/isNil';
import { getIcon } from '@/utils/ev-helpers';

export default {
  name: 'BatteryInfoComponent',

  props: {
    criticalLevel: {
      type: Number,
      default: null,
    },
    currentLevel: {
      type: Number,
      default: null,
    },
  },

  computed: {
    label() {
      return isNil(this.currentLevel)
        ? this.$t('new_booking.booking_list.vehicle.battery_level_not_available')
        : this.$t('new_booking.booking_list.vehicle.battery_level_indicator', { battery_level: this.currentLevel });
    },
  },

  methods: {
    getIcon,
  },
};
</script>

<template>
  <figure class="BatteryInfoComponent d-flex align-items-center">
    <ui-icon
      :icon="getIcon(currentLevel, criticalLevel)"
      :size="ICONS_SIZES.medium"
    />
    <figcaption class="emobg-body-small emobg-color-ink ml-1">
      {{ label }}
    </figcaption>
  </figure>
</template>
