<script>
import map from 'lodash/map';

import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';
import { compactArray } from '@emobg/web-utils';

export default {
  name: 'ExtrasComponent',

  components: {
    DetailSectionComponent,
  },

  props: {
    extras: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    list() {
      return compactArray(map(this.extras, 'name')).join(' | ');
    },
  },
};
</script>

<template>
  <DetailSectionComponent
    v-if="list"
    :title="$t('new_booking.booking_list.vehicle.specifications')"
    class="ExtrasComponent"
  >
    <div
      class="d-flex emobg-body-small emobg-color-ink-light"
      data-test-id="specifications"
    >
      {{ list }}
    </div>
  </DetailSectionComponent>
</template>
