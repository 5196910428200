<template>
  <div @click.stop>
    <ui-dropdown
      v-bind="fetchButtonSpecs()"
      :text-color="GRAYSCALE.ink"
      arrow
      data-test-id="edit_booking_actions-dropdown"
    >
      <span
        slot="trigger"
        class="d-flex justify-content-between px-3"
      >
        {{ $t('MyBookings.EditBookingDropdown.button.text') }}
      </span>
      <template v-if="bookingActions && bookingActions.length">
        <ui-dropdown-actions
          slot="content"
          :actions.prop="bookingActions"
        />
      </template>
    </ui-dropdown>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import concat from 'lodash/concat';

import { BOOKING_EVENTS } from '@Bookings/MyBookings/constants/event-handlers';
// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
import { isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'EditBookingDropdown',
  mixins: [
    EventHandlerMixin,
  ],
  props: {
    booking: {
      type: Object,
      default: () => ({}),
    },
    bookingUuid: {
      type: String,
      default: '',
    },
    isInsuranceUpgradeAvailaible: {
      type: Boolean,
      default: false,
    },
    isAdditionalDriverEnabled: {
      type: Boolean,
      default: false,
    },
    isAdditionalPassengersEnabled: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isCancelable: {
      type: Boolean,
      default: false,
    },
    isBookingRequest: {
      type: Boolean,
      default: false,
    },
    isCarpooling: {
      type: Boolean,
      default: false,
    },
    freeSeats: {
      type: Number,
      default: 0,
    },
    allowsOneWay: {
      type: Boolean,
      default: false,
    },
    allowsCarpooling: {
      type: Boolean,
      default: false,
    },
    isNonConnected: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },

  computed: {
    isBusinessProfileActive,
  },

  created() {
    this.bookingActions = [
      {
        label: this.$t('MyBookings.EditBookingDropdown.actions.cancel_booking'),
        action: () => this.emitAction(BOOKING_EVENTS.cancelBookingModalOpen),
        key: 'cancel_booking',
        visible: this.isCancelable,
        labelClass: 'emobg-color-danger',
      },
    ];

    if (this.isEditable) {
      const isAddCarpoolingVisible = this.allowsCarpooling
        && !this.isCarpooling
        && !this.isBookingRequest
        && this.freeSeats > 0
        && this.isBusinessProfileActive;
      const isEditPeopleOptionVisible = this.isAdditionalDriverEnabled || this.isAdditionalPassengersEnabled;
      const updateActions = [
        {
          label: this.$t('MyBookings.EditBookingDropdown.actions.edit_time'),
          action: () => this.emitAction(BOOKING_EVENTS.editBookingTimeModalOpen),
          key: 'edit_time',
          visible: true,
        },
        {
          label: this.$t('MyBookings.EditBookingDropdown.actions.edit_return_location'),
          action: () => this.emitAction(BOOKING_EVENTS.editBookingDropOffModalOpen),
          key: 'edit_return_location',
          visible: this.allowsOneWay,
        },
        {
          label: this.$t('MyBookings.EditBookingDropdown.actions.edit_people_from_trip'),
          action: () => this.emitAction(
            BOOKING_EVENTS.editBookingPeopleModalOpen, {
              uuid: this.bookingUuid,
              isAdditionalDriverEnabled: this.isAdditionalDriverEnabled,
              isAdditionalPassengersEnabled: this.isAdditionalPassengersEnabled,
            },
          ),
          key: 'edit_booking_people',
          visible: isEditPeopleOptionVisible,
          disabled: this.isNonConnected,
        },
        {
          label: this.$t('MyBookings.EditBookingDropdown.actions.add_carpooling'),
          action: () => this.emitAction(BOOKING_EVENTS.editBookingAddCarpooling),
          key: 'add_carpooling',
          visible: isAddCarpoolingVisible,
          disabled: this.isNonConnected,
        },
        {
          label: this.$t('MyBookings.EditBookingDropdown.actions.cancel_carpooling'),
          action: () => this.emitAction(BOOKING_EVENTS.editBookingCancelCarPooling),
          key: 'cancel_carpooling',
          visible: this.isCarpooling,
          labelClass: 'emobg-color-danger',
          disabled: this.isNonConnected,
        },
        {
          label: this.$t('MyBookings.EditBookingDropdown.actions.upgrade_insurance'),
          action: () => this.emitAction(BOOKING_EVENTS.editBookingUpgradeInsurance),
          key: 'upgrade_insurance',
          visible: this.isInsuranceUpgradeAvailaible,
        },
      ];

      this.bookingActions = concat(updateActions, this.bookingActions);
    }

    this.bookingActions = filter(this.bookingActions, 'visible');
  },

  methods: {
    emitAction(event, payload) {
      this.eventHandler.$emit(event, payload || this.bookingUuid);
    },
  },
};
</script>
