<script>
export default {
  name: 'DetailSectionComponent',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <section>
    <header
      v-if="title"
      v-once
      class="pb-1 mb-3 emobg-border-bottom-1 emobg-border-color-ground emobg-font-default emobg-font-weight-bold emobg-color-ink-light"
    >
      {{ title }}
    </header>

    <slot />
  </section>
</template>
