<script>
import { EV_ICON_PROPS } from '@/utils/ev-helpers';
import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';
import { compactArray } from '@emobg/web-utils';

export default {
  name: 'SpecificationsComponent',

  components: {
    DetailSectionComponent,
  },

  props: {
    isElectric: {
      type: Boolean,
      default: false,
    },
    fuel: {
      type: String,
      default: '',
    },
    seats: {
      type: Number,
      default: 0,
    },
    transmission: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    licensePlate: {
      type: String,
      default: '',
    },
  },

  computed: {
    specifications() {
      const fuelData = {
        icon: this.isElectric ? EV_ICON_PROPS.icon : this.ICONS.fuelDispenser,
        iconColor: this.isElectric ? EV_ICON_PROPS.color : null,
        label: this.fuel,
      };
      const seatsData = {
        icon: this.ICONS.passenger,
        label: `${this.seats} ${this.$t('new_booking.booking_list.vehicle.pax')}`,
      };
      const transmissionData = {
        icon: this.ICONS.transmission,
        label: this.transmission,
      };

      const data = [
        this.fuel ? fuelData : null,
        this.seats ? seatsData : null,
        this.transmission ? transmissionData : null,
      ];

      return compactArray(data);
    },
  },
};
</script>

<template>
  <DetailSectionComponent
    :title="$t('new_booking.booking_list.vehicle.vehicle_details')"
    class="SpecificationsComponent"
  >
    <div class="row py-2 px-2">
      <figure
        v-for="specification in specifications"
        :key="specification.label"
        class="col-6 col-xl-3 d-flex align-items-center"
      >
        <ui-icon
          :icon="specification.icon"
          :color="specification.iconColor"
          :size="ICONS_SIZES.medium"
        />
        <figcaption class="emobg-body-small emobg-color-ink-light ml-1">
          {{ specification.label }}
        </figcaption>
      </figure>
    </div>

    <div
      v-if="category"
      class="emobg-body-small emobg-color-ink-light mb-1 py-2 px-2"
    >
      {{ `${$t('new_booking.booking_list.vehicle.category')}: ${category}` }}
    </div>

    <div
      v-if="licensePlate"
      class="emobg-body-small emobg-color-ink-light mb-1 py-2 px-2"
    >
      {{ `${$t('new_booking.booking_list.vehicle.license_plate')}: ${licensePlate}` }}
    </div>
  </DetailSectionComponent>
</template>
