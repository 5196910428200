import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export const getAddressLink = ({ address, latitude, longitude }) => {
  const hasData = address && latitude && longitude;

  return hasData ? `
        <a
          class="emobg-link"
          href="https://www.google.com/maps/?q=${latitude},${longitude}"
          target="_blank"
        >
          ${address}
        </a>
      ` : FALLBACK_MESSAGE.dash;
};

export const getPhoneNumberLink = phone => (phone ? `
    <a
      class="emobg-link"
      href="tel:${phone}"
    >
      ${phone}
    </a>
  ` : FALLBACK_MESSAGE.dash);
