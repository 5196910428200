<script>
import {
  FareInformation,
  Fuel,
  MileageDetails,
} from '@/components/VehicleCard/components/TariffInformation/components';

export default {
  name: 'TariffInformationComponent',
  components: {
    FareInformation,
    MileageDetails,
    Fuel,
  },
  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },
    isElectric: {
      type: Boolean,
      default: false,
    },
    isOutsideWorkingHours: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="TariffInformationComponent">
    <FareInformation :tariff="tariff" />
    <MileageDetails :tariff="tariff" />
    <Fuel :is-electric="isElectric" />

    <div
      v-if="isOutsideWorkingHours"
      class="mb-1 emobg-body-small emobg-color-ink-light"
      data-test-id="outside_working_hours-text"
    >
      ** {{ $t('new_booking.booking_list.vehicle.outside_working_hours') }}
    </div>
  </div>
</template>
