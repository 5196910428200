<script>
import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { isLowBattery } from '@/helpers/booking/evHelpers';
import { isVehicleInUse } from './ChargeBatteryAlert';

export default {
  name: 'ChargeBatteryAlertComponent',

  components: {
    AlertComponent,
  },

  props: {
    isBeingUsed: {
      type: Boolean,
      default: false,
    },
    electricDetails: {
      type: Object,
      default: () => null,
    },
    bookingStart: {
      type: String,
      default: '',
    },
    operator: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    this.textAlert = this.getAlertText();
    this.ALERT_TYPES = ALERT_TYPES;
  },

  methods: {
    getAlertText() {
      if (isLowBattery(this.bookingStart, this.operator, this.electricDetails)) {
        return this.$t('carsharing_personal.low_battery_alert');
      }

      if (isVehicleInUse(this.isBeingUsed, this.bookingStart, this.operator, this.electricDetails)) {
        return this.$t('carsharing_personal.currently_in_use_battery_alert');
      }

      return null;
    },
  },
};
</script>

<template>
  <AlertComponent
    v-if="textAlert"
    v-once
    :type="ALERT_TYPES.warning"
  >
    <div v-html="textAlert" />
  </AlertComponent>
</template>
