<script>
import moment from 'moment';
import get from 'lodash/get';
import ceil from 'lodash/ceil';
import debounce from 'lodash/debounce';
import last from 'lodash/last';
import head from 'lodash/head';

import { DATE_FORMAT, SPEED } from '@emobg/web-utils';

import { BLOCK_MARGIN_SIZE, BLOCK_WIDTH, COLORS } from './constants';

const DEFAULT_TIMELINE_BLOCK_CARDINAL = 30;

export default {
  name: 'VehicleTimelineComponent',

  filters: {
    displayQuarter(blockTime) {
      return blockTime.start.minute() === 0
        ? blockTime.start.format(DATE_FORMAT.timeOfDay)
        : `${blockTime.start.minutes()}'`;
    },
  },

  props: {
    start: {
      type: Object,
      required: true,
      validator: startDateArg => moment.isMoment(startDateArg),
    },
    end: {
      type: Object,
      required: true,
      validator: endDateArg => moment.isMoment(endDateArg),
    },
    blockStep: {
      type: Object,
      default: () => moment.duration(15, 'minutes'),
    },
    timeBlocksBusy: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      blocks: [],
    };
  },

  mounted() {
    const initTimelineListCardinal = ceil(get(this.$refs, 'timelineList.clientWidth', 0)) / (BLOCK_WIDTH + (2 * BLOCK_MARGIN_SIZE));
    const initBlocks = initTimelineListCardinal || DEFAULT_TIMELINE_BLOCK_CARDINAL;

    this.initializeTimeblocks(initBlocks);
    this.moveLeft();
  },

  created() {
    window.addEventListener('resize', debounce(this.updateBlocksOnResize, SPEED.medium));
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateBlocksOnResize);
  },

  methods: {
    initializeTimeblocks(blocksSize) {
      const currentTime = moment.isMoment(this.start)
        ? this.start.clone()
        : moment(this.start).clone();

      const closestQuarter = Math.round(currentTime.minute() / 15) * 15;

      const time = closestQuarter === 60
        ? { h: currentTime.hour() + 1, m: 0 }
        : { m: closestQuarter };

      currentTime.set(time);
      for (let i = 0; i < blocksSize; i += 1) {
        const start = currentTime.clone();
        const end = currentTime.clone().add(this.blockStep);
        this.blocks = [...this.blocks, {
          start,
          end,
          color: this.getColor(start, end),
        }];

        currentTime.add(this.blockStep);
      }
    },
    updateBlocksOnResize() {
      const newTimelineListCardinal = ceil(get(this.$refs, 'timelineList.clientWidth', 0)) / (BLOCK_WIDTH + (2 * BLOCK_MARGIN_SIZE));
      const difference = newTimelineListCardinal - this.blocks.length;
      if (difference >= 1) {
        this.prependBlocks(difference);
      }
    },
    prependBlocks(blocksSize) {
      for (let i = 0; i < blocksSize; i += 1) {
        const lastBlock = last(this.blocks);
        this.blocks = [...this.blocks, this.getNextTimeblock(lastBlock)];
      }
    },
    getColor(start, end) {
      const isTimeblockBusy = this.timeBlocksBusy.some(
        availabilityBlock => start.isBetween(availabilityBlock.start_block, availabilityBlock.end_block)
          || end.isBetween(availabilityBlock.start_block, availabilityBlock.end_block),
      );

      if (isTimeblockBusy) {
        return COLORS.busyTimeblock;
      }
      return start.isBetween(this.start, this.end, null, '[]') || end.isBetween(this.start, this.end)
        ? COLORS.available
        : COLORS.notSelected;
    },
    moveLeft(event) {
      const firstBlock = head(this.blocks);
      this.blocks.pop();
      this.blocks.unshift(this.getPreviousTimeblock(firstBlock));
      if (event) {
        event.stopPropagation();
      }
    },
    moveRight(event) {
      const lastBlock = last(this.blocks);
      this.blocks.shift();
      this.blocks = [...this.blocks, this.getNextTimeblock(lastBlock)];
      if (event) {
        event.stopPropagation();
      }
    },
    getPreviousTimeblock(timeblock) {
      const start = timeblock.start.clone().subtract(this.blockStep);
      const end = timeblock.end.clone().subtract(this.blockStep);
      return {
        start,
        end,
        color: this.getColor(start, end),
      };
    },
    getNextTimeblock(timeblock) {
      const start = timeblock.start.clone().add(this.blockStep);
      const end = timeblock.end.clone().add(this.blockStep);
      return {
        start,
        end,
        color: this.getColor(start, end),
      };
    },
  },
};
</script>

<template>
  <div class="VehicleTimelineComponent d-flex">
    <div class="ml-auto">
      <ui-icon
        :icon="ICONS.arrowLeftDouble"
        :size="ICONS_SIZES.xSmall"
        :color="GRAYSCALE.inkLight"
        class="mr-2"
        @click="moveLeft"
      />
    </div>
    <ul
      ref="timelineList"
      class="VehicleTimelineComponent__timeblocklist d-flex overflow-hidden p-0 m-0 flex-grow-1"
    >
      <li
        v-for="(timeblock, index) in blocks"
        :key="index"
        class="mx-1 d-flex align-items-center justify-content-center flex-column"
      >
        <span
          :class="[
            'd-block',
            'VehicleTimelineComponent__timeblock',
            `emobg-background-color-${timeblock.color}`,
          ]"
        />

        <p
          :class="[
            `emobg-color-ink${timeblock.start.minute() === 0 ? '' : '-light'}`,
            'emobg-font-x-small',
            'text-center',
            'mt-1 mb-0',
          ]"
        >
          {{ timeblock | displayQuarter }}
        </p>
      </li>
    </ul>
    <div class="mr-auto">
      <ui-icon
        :icon="ICONS.arrowRightDouble"
        :size="ICONS_SIZES.xSmall"
        :color="GRAYSCALE.inkLight"
        class="ml-2"
        data-test-id="vehicle-timeline-next-button"
        @click="moveRight"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.VehicleTimelineComponent {
  &__timeblocklist {
    list-style: none;
  }

  &__timeblock {
    width: 37px;
    height: 10px;
  }
}
</style>
