import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import every from 'lodash/every';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import filter from 'lodash/filter';
import { useTranslations } from '@/composable/App/Translations';
const { $t } = useTranslations();

export const hasUnlimitedMileage = tariff => get(tariff, 'mileage_included', false);

export const hasFreeMileage = tariff => get(tariff, 'free_mileage') || false;

export const hasNotFreeMileage = tariff => {
  const ranges = get(tariff, 'mileage_price_ranges', []);
  return ranges.length && every(ranges, ({ price_raw: price }) => price !== 0) && !hasFreeMileage(tariff);
};

export const hasAllRangesSamePrice = tariff => {
  const ranges = cloneDeep(get(tariff, 'mileage_price_ranges', []));

  if (ranges.length && get(ranges, '[0].price_raw') === 0 && get(ranges, '[0].start_range') === 0) {
    ranges.shift();
  }

  const minRangePriceItem = minBy(ranges, 'price_raw');
  const maxRangePriceItem = maxBy(ranges, 'price_raw');

  return ranges.length && get(minRangePriceItem, 'price_raw') === get(maxRangePriceItem, 'price_raw') && !hasUnlimitedMileage(tariff);
};

export const getMinimumPrice = tariff => {
  const ranges = get(tariff, 'mileage_price_ranges', []);
  const pricedRanges = filter(ranges, ({ price_raw: price }) => price !== 0);
  const minRangePriceItem = minBy(pricedRanges, 'price_raw');

  return `${get(minRangePriceItem, 'price')}/${get(minRangePriceItem, 'units')}`;
};

export const getMaximumPrice = tariff => {
  const ranges = get(tariff, 'mileage_price_ranges', []);
  const pricedRanges = filter(ranges, ({ price_raw: price }) => price !== 0);
  const maxRangePriceItem = maxBy(pricedRanges, 'price_raw');

  return `${get(maxRangePriceItem, 'price')}/${get(maxRangePriceItem, 'units')}`;
};

export const extraMileageText = tariff => {
  const formattedMin = getMinimumPrice(tariff);
  const formattedMax = getMaximumPrice(tariff);
  const hasSamePrice = hasAllRangesSamePrice(tariff);

  const differentPrice = $t('tariff.price_component.extra_mileage_different_price_text', {
    minimumPrice: formattedMin,
    maximumPrice: formattedMax,
  });
  const samePrice = $t('tariff.price_component.extra_mileage_same_price_text', {
    minimumPrice: formattedMin,
  });

  return hasSamePrice ? samePrice : differentPrice;
};

export const freeMileageText = tariff => {
  const isMigrated = get(tariff, 'is_migrated', false);
  const legacyText = $t('tariff.price_component.free_legacy_mileage_text', { mileage: get(tariff, 'free_mileage') });
  const migratedText = $t('tariff.price_component.free_migrated_mileage_text', { mileage: get(tariff, 'free_mileage') });

  return isMigrated ? migratedText : legacyText;
};
