import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import { isBookingCloseIntoFuture } from '@/helpers/booking/bookingHelpers';
import { isMissingBattery } from '@/utils/ev-helpers';

/**
 * Return if booking start is close into the future and EV is currently in other booking
 * @param {boolean} isBeingUsed
 * @param {string} bookingStart
 * @param {object} operator - { configuration: { close_into_future: 30 } }
 * @param {object} electricDetails - { low_battery_level: 20, power_battery_level: 25 }
 * @return {boolean}
 */
export const isVehicleInUse = (isBeingUsed, bookingStart, operator, electricDetails) => {
  const powerBatteryLevel = get(electricDetails, 'power_battery_level', null);
  const hasBattery = !isMissingBattery(powerBatteryLevel);

  const isInUse = isBoolean(isBeingUsed) ? isBeingUsed : false;

  return hasBattery && isInUse && isBookingCloseIntoFuture({ start: bookingStart }, operator);
};
