<script>
import { EV_ICON_PROPS } from '@/utils/ev-helpers';

export default {
  name: 'ModelComponent',

  props: {
    model: {
      type: String,
      default: '',
    },
    isElectric: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.EV_ICON_PROPS = EV_ICON_PROPS;
  },
};
</script>

<template>
  <div
    v-if="model"
    v-once
    class="
      d-flex align-items-center mb-1
      emobg-font-weight-bold emobg-font-x-large emobg-font-line-height-large emobg-color-ink
    "
  >
    <ui-icon
      v-if="isElectric"
      v-bind="EV_ICON_PROPS"
    />
    <span data-test-id="model">
      {{ model }}
    </span>
  </div>
</template>
