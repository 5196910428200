<script>
import { isFutureBooking } from '@/helpers/booking/evHelpers';
import { hasEnoughBattery } from './ChargeBatteryTooltip';

export default {
  name: 'ChargeBatteryTooltipComponent',

  props: {
    isBeingUsed: {
      type: Boolean,
      default: false,
    },
    electricDetails: {
      type: Object,
      default: () => null,
    },
    bookingStart: {
      type: String,
      default: '',
    },
    operator: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    const isTooltipVisible = hasEnoughBattery(this.isBeingUsed, this.bookingStart, this.operator, this.electricDetails)
      || isFutureBooking(this.bookingStart, this.operator, this.electricDetails);

    this.tooltipText = isTooltipVisible ? this.$t('carsharing_personal.battery_may_change_tooltip') : '';
  },
};
</script>

<template>
  <ui-tooltip
    v-if="tooltipText"
    :color="COLORS.warning"
    :tooltip="tooltipText"
    contrast
  >
    <ui-icon
      :icon="ICONS.infoAlone"
      :size="ICONS_SIZES.small"
      class="emobg-background-color-warning circle p-1"
    />
  </ui-tooltip>
</template>
