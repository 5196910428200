var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VehicleTimelineComponent d-flex"},[_c('div',{staticClass:"ml-auto"},[_c('ui-icon',{staticClass:"mr-2",attrs:{"icon":_vm.ICONS.arrowLeftDouble,"size":_vm.ICONS_SIZES.xSmall,"color":_vm.GRAYSCALE.inkLight},on:{"click":_vm.moveLeft}})],1),_c('ul',{ref:"timelineList",staticClass:"VehicleTimelineComponent__timeblocklist d-flex overflow-hidden p-0 m-0 flex-grow-1"},_vm._l((_vm.blocks),function(timeblock,index){return _c('li',{key:index,staticClass:"mx-1 d-flex align-items-center justify-content-center flex-column"},[_c('span',{class:[
          'd-block',
          'VehicleTimelineComponent__timeblock',
          `emobg-background-color-${timeblock.color}`,
        ]}),_c('p',{class:[
          `emobg-color-ink${timeblock.start.minute() === 0 ? '' : '-light'}`,
          'emobg-font-x-small',
          'text-center',
          'mt-1 mb-0',
        ]},[_vm._v(" "+_vm._s(_vm._f("displayQuarter")(timeblock))+" ")])])}),0),_c('div',{staticClass:"mr-auto"},[_c('ui-icon',{staticClass:"ml-2",attrs:{"icon":_vm.ICONS.arrowRightDouble,"size":_vm.ICONS_SIZES.xSmall,"color":_vm.GRAYSCALE.inkLight,"data-test-id":"vehicle-timeline-next-button"},on:{"click":_vm.moveRight}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }