<script>
import includes from 'lodash/includes';
import { COLORS, PLACEMENTS } from '@emobg/web-components';

const CONTRAST_COLORS = [COLORS.warning];

export default {
  name: 'TooltipComponent',

  props: {
    color: {
      type: String,
      default: COLORS.primary,
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: undefined,
    },
    placement: {
      type: String,
      default: PLACEMENTS.bottom,
    },
  },

  created() {
    this.contrast = includes(CONTRAST_COLORS, this.color);
    this.tooltip = `<div class="emobg-font-weight-bold text-center emobg-font-x-small">${this.text}</div>`;
  },
};
</script>

<template>
  <ui-tooltip
    v-if="text"
    v-once
    :color="color"
    :tooltip="tooltip"
    :contrast="contrast"
    :placement="placement"
    no-offset
  >
    <ui-icon
      v-if="icon"
      :icon="icon"
      :size="ICONS_SIZES.xSmall"
    />
    <slot />
  </ui-tooltip>
</template>
