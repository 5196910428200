import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import { isBookingCloseIntoFuture } from '@/helpers/booking/bookingHelpers';
import { isDangerousLevel, isMissingBattery } from '@/utils/ev-helpers';

/**
 * Return if vehicle has enough battery or not (battery level is not dangerous) for the next booking
 * @param {string} bookingStart
 * @param {object} operator - { configuration: { close_into_future: 30 } }
 * @param {object} electricDetails - { low_battery_level: 20, power_battery_level: 25 }
 * @return {boolean}
 */
export const hasEnoughBattery = (isBeingUsed, bookingStart, operator, electricDetails = null) => {
  const lowBatteryLevel = get(electricDetails, 'low_battery_level', null);
  const powerBatteryLevel = get(electricDetails, 'power_battery_level', null);

  const hasBattery = !isMissingBattery(powerBatteryLevel);

  const isInUse = isBoolean(isBeingUsed) ? isBeingUsed : false;

  return hasBattery
    && !isInUse
    && !isDangerousLevel(powerBatteryLevel, lowBatteryLevel)
    && isBookingCloseIntoFuture({ start: bookingStart }, operator);
};
