<script>
import get from 'lodash/get';
import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';
export default {
  name: 'FareInformation',
  components: {
    DetailSectionComponent,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  methods: {
    get,
  },
};
</script>
<template>
  <DetailSectionComponent
    :title="$t('tariff.fare_information.title')"
    class="mb-4"
  >
    <p class="mb-3">
      {{ $t('tariff.fare_information.hour_fare_title') }}: {{ $t('tariff.information.price_per_hour', { price: get(tariff, 'hourly_price', '') }) }} <br>
      <span class="emobg-color-ink-light">{{ $t('tariff.fare_information.hour_fare_text') }}</span>
    </p>
    <p>
      {{ $t('tariff.fare_information.day_fare_title') }}: {{ $t('tariff.information.price_per_day', { price: get(tariff, 'daily_price', '') }) }} <br>
      <span class="emobg-color-ink-light">{{ $t('tariff.fare_information.day_fare_text') }}</span>
    </p>
  </DetailSectionComponent>
</template>
