<script>
import get from 'lodash/get';
import {
  isBookingCloseIntoFuture,
  isLocationConfirmed,
  isLocationNotConfirmed,
  isWaitingForLocation,
} from '@/helpers/booking/bookingHelpers';
import { isFutureBooking } from '@/helpers/booking/evHelpers';
import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { getAddressLink, getPhoneNumberLink } from './BookingAlert';

export default {
  name: 'BookingAlertComponent',

  components: {
    AlertComponent,
  },

  props: {
    booking: {
      type: Object,
      default: null,
    },
    operator: {
      type: Object,
      default: null,
    },
  },

  created() {
    this.alertData = this.getAlertData();
  },

  methods: {
    getGeofenceStatus(booking) {
      const isGeofence = get(booking, 'location.is_geofence', false);

      return isGeofence && get(booking, 'vehicle_location_status', null);
    },

    isBeingUsed(booking, operator) {
      const isBeingUsed = get(booking, 'vehicle.is_being_used', false);

      return isBeingUsed && isBookingCloseIntoFuture(booking, operator);
    },

    getAlertData() {
      const geofenceStatus = this.getGeofenceStatus(this.booking);

      const bookingStart = get(this, 'booking.start');
      const electricDetails = get(this, 'booking.vehicle.electric_details');

      if (isWaitingForLocation(geofenceStatus)) {
        return {
          type: ALERT_TYPES.warning,
          text: this.$t('views.my_bookings.vehicle_location_status_waiting_for_location'),
        };
      }

      if (isLocationNotConfirmed(geofenceStatus)) {
        const phone = get(this, 'operator.customer_service_phone');
        const phoneLink = getPhoneNumberLink(phone);
        return {
          type: ALERT_TYPES.danger,
          text: this.$t('views.my_bookings.vehicle_location_status_location_not_confirmed', {
            phone: phoneLink,
          }),
        };
      }

      if (isLocationConfirmed(geofenceStatus)) {
        const {
          address,
          latitude,
          longitude,
        } = get(this, 'booking.vehicle') || {};

        const addressLink = getAddressLink({
          address,
          latitude,
          longitude,
        });

        return {
          type: ALERT_TYPES.success,
          text: this.$t('views.my_bookings.vehicle_location_status_location_confirmed', {
            address: addressLink,
          }),
        };
      }

      if (isFutureBooking(bookingStart, this.operator, electricDetails)) {
        return {
          type: ALERT_TYPES.warning,
          text: this.$t('carsharing_personal.battery_may_change_tooltip'),
        };
      }

      if (this.isBeingUsed(this.booking, this.operator)) {
        return {
          type: ALERT_TYPES.warning,
          text: this.$t('new_booking.booking_list.vehicle.vehicle_is_being_used'),
        };
      }

      return null;
    },
  },
};
</script>

<template>
  <AlertComponent
    v-if="alertData"
    :type="alertData.type"
  >
    <div
      @click.stop
      v-html="alertData.text"
    />
  </AlertComponent>
</template>
