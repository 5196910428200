<script>
export default {
  name: 'LicensePlateComponent',
};
</script>

<template>
  <div
    v-if="$slots.default"
    class="
      LicensePlateComponent
      position-relative d-inline-flex
      emobg-border-1 emobg-border-color-black emobg-border-radius-default
      overflow-hidden
    "
  >
    <span
      class="d-flex align-items-center px-2 emobg-font-line-height-large"
      data-test-id="license-plate-content"
    >
      <slot />
    </span>
  </div>
</template>
