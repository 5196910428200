import get from 'lodash/get';
import union from 'lodash/union';
import reject from 'lodash/reject';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';

import { camelCaseKeys } from '@emobg/web-utils';

import { useTranslations } from '@/composable/App/Translations';

import INPUT_TYPES from '@/constants/inputTypes';

const { $t } = useTranslations();

/**
 * @function getCostAllocationsComponentsData
 * @desc It maps user profile cost allocations and enrich with data to be used on the form components
 *
 * @param {Array<Object>} costAllocations
 * @param {Array<Object>} options
 * @param {Array<Object>} filledCostAllocations
 * @example <caption>Example usage of getCostAllocationsComponentsData.</caption>
 * // returns "[
    {
        "component": {}, // Long object
        "name": "allocation_2",
        "inputType": "text",
        "uuid": "<uuid>",
        "updateEvent": "input",
        "dataTestId": "cost_allocation_allocation_2-input_text",
        "props": {
            "name": "allocation_2",
            "label": "allocation_2",
            "placeholder": "allocation_2"
        },
        "validation": {}
    }
]"
 * getCostAllocationsComponentsData([{
    "uuid": "<uuid>",
    "name": "department_name",
    "code": null,
    "format": null,
    "type": "profile",
    "required": false,
    "input_type": "text",
    "children": [
        {
            "uuid": "<uuid>",
            "name": "department_name",
            "code": "Test1",
            "format": null,
            "type": "profile",
            "required": false,
            "input_type": "text",
            "children": null
        }
    ]
}])
 * @returns {Array<Object>}
 */
export const getCostAllocations = ({ costAllocations, options }) => map(costAllocations, costAllocation => {
  const selectedCostAllocation = get(costAllocation, 'children');
  const optionsFromCostAllocation = find(options, ['name', get(costAllocation, 'name')]);
  const costAllocationOptions = reject(get(optionsFromCostAllocation, 'children'), ['code', get(selectedCostAllocation, '0.code')]);
  const isSelect = costAllocation.input_type === INPUT_TYPES.select;
  const specificProps = isSelect
    ? { options:
        map(uniqBy(union(selectedCostAllocation, costAllocationOptions), 'code'), ({ uuid, code }) => ({ value: uuid, label: code })) }
    : {};

  const costAllocationName = get(costAllocation, 'name');
  return {
    ...camelCaseKeys(pick(costAllocation, ['name', 'input_type', 'uuid'])),
    dataTestId: `cost_allocation_${costAllocationName}-${isSelect ? 'select' : 'input_text'}`,
    ...specificProps,
    props: {
      ...pick(costAllocation, ['name']),
      label: costAllocationName,
      placeholder: costAllocationName,
      value: get(costAllocation, `children.0.${
        isSelect
          ? 'uuid'
          : 'code'
      }`),
    },
    validation: {
      ...(get(costAllocation, 'required')
        ? {
          isRequired: {
            message: $t('refactor.company_signup.errors.mandatory'),
            whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
          },
        }
        : {}
      ),
      ...(isEmpty(get(costAllocation, 'format')) ? {} : {
        isPattern: {
          pattern: new RegExp(get(costAllocation, 'format')),
          message: $t('common.text_input.error.pattern'),
        },
      }),
    },
  };
});

/**
 * @function generateSaveCostAllocationPayload
 * @desc It maps enriched cost allocations data from the component and
 * generate cost allocations data in required structure for save cost allocations endpoint
 *
 * @param {Array<Object>} costAllocations
 * @example <caption>Example usage of generateSaveCostAllocationPayload.</caption>
 * // returns "[
    {
        "parent_uuid": "<uuid>",
        "type": "text",
        "value": "<some_value_1>",
    },
    {
        "parent_uuid": "<uuid_2>",
        "type": "select",
        "value": "<some_value_2>",
    },
]"
 * generateSaveCostAllocationPayload([
    {
        "component": {}, // Long object
        "name": "allocation_1",
        "inputType": "text",
        "uuid": "<uuid>",
        "updateEvent": "input",
        "dataTestId": "cost_allocation_allocation_1-input_text",
        "props": {
            "name": "allocation_2",
            "label": "allocation_2",
            "placeholder": "allocation_2",
            "modelValue": "<some_value_1>"
        },
        "validation": {}
    },
    {
        "component": {}, // Long object
        "name": "allocation_2",
        "inputType": "select",
        "uuid": "<uuid_2>",
        "updateEvent": "change",
        "dataTestId": "cost_allocation_allocation_2-select",
        "props": {
            "name": "allocation_2",
            "label": "allocation_2",
            "placeholder": "allocation_2"
            "modelValue": "<some_value_2>"
        },
        "validation": {}
    },
])
 * @returns {Array<Object>}
 */
export const generateSaveCostAllocationPayload = ({ costAllocations }) => filter(map(costAllocations, costAllocation => ({
  parent_uuid: get(costAllocation, 'uuid'),
  type: get(costAllocation, 'inputType'),
  value: get(costAllocation, 'props.value'),
})),
costAllocation => !isEmpty(costAllocation.value));
